import axios from "axios";
import setAuthToken from "../lib/setAuthToken";
import config from "../lib/config";
import { decodeJwt } from "./jsonWebToken";
axios.defaults.headers.common['authorization'] = localStorage.getItem("admin_token")

export const login = async (data, dispatch) => {
  try {
    // // console.log(data,'dataa')
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const changepassword = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/changePassward`,
      data
    });

    return {
      loading: true,
      result: respData.data.result
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    };
  }
};

export const setCurrentUser = (decoded) => {
  console.log(decoded, "decodeddecodeddecoded");
  return {
    type: "SET_CURRENT_USER",
    payload: decoded
  };
};

export const save_faq = async (data) => {
  console.log(data, "datatatata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/save_faq`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const updateAddress = async (data) => {
  console.log(data, "datatatata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateAddress`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const update_faq = async (data) => {
  console.log(data, "datatatata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/update_faq`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const delete_faq = async (data) => {
  console.log(data, "datatatata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/delete_faq`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_faq = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_faq`
    });
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_users_data = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_users_data`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const add_token_price = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/add_token_price`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const add_token_price1 = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/add_token_price1`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const add_futurewallet = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/add_futurewallet`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_token_price = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_token_price`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const SetCommunitySettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/SetCommunitySettings`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data,
      status: respData.data.status
      // error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const Get_user_settings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_user_setting`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const set_cleing_setting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_cleing_setting`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const set_roi_setting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_roi_setting`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const set_direct_setting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_direct_setting`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_user_setting = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_user_setting`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const set_adminPer_setting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_adminPer_setting`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const set_commonsettings_setting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_commonsettings_setting`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_binaryIncome_data = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_binaryIncome_data`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_depositBinaryHistory_data = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_depositBinaryHistory_data`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_depositCommunityHistory_data = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_depositCommunityHistory_data`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_roiIncome_data = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_roiIncome_data`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData.data.result,
      status: respData.data.status,
      error: respData.data.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      error: err.response.data.errors
    };
  }
};

export const get_dashboard_datas = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_dashboard_datas`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};

export const get_withdraw_datas = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get_withdraw_datas`
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};

export const set_block_user = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/set_block_user`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};

export const getDepositeHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getDepositeHistory`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};
export const getRetopupeHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getRetopupeHistory`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};
export const getRetopupeHistoryComm = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getRetopupeHistoryComm`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};
export const getWithDrawHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getWithDrawHistory`,
      data
    });
    console.log(respData, "respDatakkkk");
    return {
      loading: true,
      result: respData?.data,
      status: respData?.data?.status,
      error: respData?.data?.errors
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.data?.status,
      error: err.response?.data?.errors
    };
  }
};

import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";
import RetopupHistory from "./depositeHistory/retopupHistory";

const DepositeHistory = lazy(() => import("./depositeHistory/depositeHistory"));
const getRetopupeHistoryComm = lazy(() =>
  import("./depositeHistory/retopupHistoryComm")
);
const IncomeHistory = lazy(() => import("./incomeHis/allIncomes"));
const communityWithdraw = lazy(() => import("./widthdraw/communityWithdraw"));
const roiwithdraw = lazy(() => import("./widthdraw/roiwithdraw"));
const Futurewidthdraw = lazy(() => import("./widthdraw/Futurewidthdraw"));
const binearyWithdraw = lazy(() => import("./widthdraw/binearyWithdraw"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const UserHistory = lazy(() => import("./History/UserHistory.js"));
const userlist1 = lazy(() => import("./users/userlist1"));
const userlist2 = lazy(() => import("./users/userlist2"));
// const Activeusers = lazy(() => import("./History/Activeusers"));
// const InactiveUsers = lazy(() => import("./History/InactiveUsers"));
const communityUsers = lazy(() => import("./History/communityUsers"));
const BinaryUsers = lazy(() => import("./History/BinaryUsers"));
const ListFAQ = lazy(() => import("./FAQ/ListFAQ.js"));
const AddFAQ = lazy(() => import("./FAQ/AddFAQ.js"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Settings = lazy(() => import("./Setting/Settings"));
const AddressUpdate = lazy(() => import("./users/AddressUpdate"));
const UserSetting = lazy(() => import("./Setting/UserSetting"));
const binaryIncome = lazy(() => import("./History/binaryIncome"));
const depositCommunityHistory = lazy(() =>
  import("./History/depositCommunityHistory")
);
const depositBinaryHistory = lazy(() =>
  import("./History/depositBinaryHistory")
);

const test = lazy(() => import("./Setting/test"));

const roiIncome = lazy(() => import("./History/roiIncome"));

const Login = lazy(() => import("./user-pages/Login"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        <ConditionRoute
          path="/History/UserHistory"
          component={UserHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/income/income-history"
          component={IncomeHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/users/userlist1"
          component={userlist1}
          type={"private"}
        />

        <ConditionRoute
          path="/users/userlist2"
          component={userlist2}
          type={"private"}
        />
        <ConditionRoute
          path="/users/address-update"
          component={AddressUpdate}
          type={"private"}
        />
        {/* <ConditionRoute
          path="/History/Activeusers"
          component={Activeusers}
          type={"private"}
        />

        <ConditionRoute
          path="/History/InactiveUsers"
          component={InactiveUsers}
          type={"private"}
        /> */}

        <ConditionRoute
          path="/History/communityUsers"
          component={communityUsers}
          type={"private"}
        />

        <ConditionRoute
          path="/History/BinaryUsers"
          component={BinaryUsers}
          type={"private"}
        />

        <ConditionRoute
          path="/FAQ/ListFAQ"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/FAQ/AddFAQ"
          component={AddFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/ChangePassword"
          component={ChangePassword}
          type={"private"}
        />

        <ConditionRoute
          path="/Setting/Settings"
          component={Settings}
          type={"private"}
        />

        <ConditionRoute
          path="/Setting/UserSetting"
          component={UserSetting}
          type={"private"}
        />

        <ConditionRoute
          path="/user-pages/login-1"
          component={Login}
          type={"auth"}
        />

        <ConditionRoute
          path="/History/binaryIncome"
          component={binaryIncome}
          type={"private"}
        />
        <ConditionRoute
          path="/History/depositBinaryHistory"
          component={depositBinaryHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/History/depositCommunityHistory"
          component={depositCommunityHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/History/roiIncome"
          component={roiIncome}
          type={"private"}
        />

        <ConditionRoute
          path="/deposit/DepositHistory"
          component={DepositeHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/deposit/retopUpHistory"
          component={RetopupHistory}
          type={"private"}
        />
        <ConditionRoute
          path="/deposit/retopUpHistoryComm"
          component={getRetopupeHistoryComm}
          type={"private"}
        />
        <ConditionRoute
          path="/withdraw/matching-direct-withdraw"
          component={binearyWithdraw}
          type={"private"}
        />
        <ConditionRoute
          path="/withdraw/Futurewidthdraw"
          component={Futurewidthdraw}
          type={"private"}
        />
        <ConditionRoute
          path="/withdraw/roiwithdraw"
          component={roiwithdraw}
          type={"private"}
        />
        <ConditionRoute
          path="/withdraw/communityWithdraw"
          component={communityWithdraw}
          type={"private"}
        />
        <ConditionRoute path="/test" component={test} type={"private"} />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}

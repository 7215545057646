import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/History", state: "basicUiMenuOpen" },
      { path: "/FAQ", state: "advancedUiMenuOpen" },
      { path: "/Setting", state: "formElementsMenuOpen" },
      { path: "/History/community", state: "tablesMenuOpen" },
      { path: "/users", state: "mapsMenuOpen" },
      { path: "/income", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/deposit", state: "depositePagesMenuOpen" },
      { path: "/withdraw", state: "withdrawPagesMenuOpen" }
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/users") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.mapsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("mapsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Users</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={this.state.mapsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/users/userlist1")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/users/userlist1"
                  >
                    <Trans>Block User</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/users/userlist2")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/users/userlist2"
                  >
                    <Trans>View User Dashboard</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/users/userlist2")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/users/address-update"
                  >
                    <Trans>Address Update</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/History") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.basicUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("basicUiMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>History</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-history menu-icon"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/UserHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/UserHistory"
                  >
                    <Trans>All Users</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/communityUsers")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/communityUsers"
                  >
                    <Trans>Community Users</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/BinaryUsers")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/BinaryUsers"
                  >
                    <Trans>ROI Users</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/binaryIncome")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/binaryIncome"
                  >
                    <Trans>Matching Income</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/depositBinaryHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/depositBinaryHistory"
                  >
                    <Trans>Direct Income </Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/depositCommunityHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/depositCommunityHistory"
                  >
                    <Trans>Community Income</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/History/roiIncome")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/History/roiIncome"
                  >
                    <Trans>ROI Income</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/FAQ") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.advancedUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("advancedUiMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>FAQ</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={this.state.advancedUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/FAQ/ListFAQ")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/FAQ/ListFAQ"
                  >
                    <Trans>List FAQ</Trans>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/basic-ui/dropdowns")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/basic-ui/dropdowns"
                  >
                    <Trans>List COllection</Trans>
                  </Link>
                </li> */}
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/Setting") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("formElementsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Settings</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-settings menu-icon"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/Setting/Settings")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/Setting/Settings"
                  >
                    <Trans>Community Setting</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/Setting/UserSetting")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/Setting/UserSetting"
                  >
                    <Trans>Matching Setting</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/deposit") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.depositePagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("depositePagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Deposit</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-settings menu-icon"></i>
            </div>
            <Collapse in={this.state.depositePagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/deposit/DepositHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/deposit/DepositHistory"
                  >
                    <Trans>Deposit List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/deposit/retopUpHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/deposit/retopUpHistory"
                  >
                    <Trans>Retopup ROI List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/deposit/retopUpHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/deposit/retopUpHistoryComm"
                  >
                    <Trans>Retopup Community List</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/deposit") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.withdrawPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("withdrawPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Withdraw History</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-settings menu-icon"></i>
            </div>
            <Collapse in={this.state.withdrawPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/withdraw/matching-direct-withdraw")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/withdraw/matching-direct-withdraw"
                  >
                    <Trans>Matching & Direct Withdraw List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/withdraw/Futurewidthdraw")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/withdraw/Futurewidthdraw"
                  >
                    <Trans>Future Withdraw List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/withdraw/communityWithdraw")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/withdraw/communityWithdraw"
                  >
                    <Trans>Community Withdraw List</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/withdraw/roiwithdraw")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/withdraw/roiwithdraw"
                  >
                    <Trans>ROI Withdraw List</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/deposit") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.iconsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("iconsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Income History</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-settings menu-icon"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/income/income-history")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/income/income-history"
                  >
                    <Trans>Income History</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);

import React, { useEffect, useState } from "react";
import { getRetopupeHistory } from "../actions/admin";
import { Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";

const DepositeHistory = () => {
  const [search, setSearch] = useState("");
  const [CateFilter, setCateFilter] = useState("all");
  const [datas, setdate] = useState([]);
  const [settings, setSettings] = useState([]);
  const [specifdate, setSpecificDate] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    getinitialData();
  }, [search, CateFilter]);

  const getinitialData = async () => {
    try {
      const Payload = {
        // search:search,
        cataFilter: CateFilter
      };
      const data = await getRetopupeHistory(Payload);
      console.log(data, "DepositBinaryHistory");
      if (data?.result?.data.length > 0) {
        console.log("runn");
        await data?.result?.data?.map((ele, i) => {
          ele.SSNo = i + 1;
        });

        const FilteredItems = data?.result?.data?.filter(
          (list) =>
            JSON.stringify(list).toLowerCase().indexOf(search.toLowerCase()) !==
            -1
        );

        console.log(FilteredItems, "runn");
        if (FilteredItems) {
          setdate(FilteredItems);
          setSettings(data?.result?.settings);
        }
        // setdate(data?.result?.data)
      } else {
        setdate([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };
  console.log(search);

  const columns = [
    {
      name: "S.no",
      width: "100px",
      selector: (row, index) => row.SSNo
    },
    {
      name: "Address",
      width: "180px",
      selector: (row) => <a>{halfAddrShow(row.address)}</a>
    },
    {
      name: "User ID",
      selector: (row) => <a>{row.userID}</a>
    },
    {
      name: "Username",
      selector: (row) => <a>{row?.userData?.userName}</a>
    },
    {
      name: "Amount",
      selector: (row) => <a>{row.amount}</a>
    },
    {
      name: "Deduction Amount",
      selector: (row) => <a>{row.treePercentage}</a>
    },
    {
      name: "Hash",
      selector: (row) => (
        <>
          {row.userData?.transactionHash ? (
            <a
              target="_blank"
              href={`https://bscscan.com/tx/${row.userData?.transactionHash}`}
            >
              View
            </a>
          ) : (
            <>-</>
          )}
        </>
      )
    },
    {
      name: "Date",
      width: "200PX",
      selector: (row) => moment(row.createdAt).format("LL")
    }
  ];

  // async function DateChange(from, val) {
  //   // if (from == "StartDateDropDown") {
  //     if (val == "PickSpecificDate") {
  //       ModalAction("calendar_modal", true);
  //     } else {
  //       setSpecificDate("Right after listing");
  //     }
  //   }

  // async function ModalAction(id, type) {
  //   // window.$("#" + id).modal(type);
  //   if (id == "calendar_modal") {
  //     const Clocktime=new Date()
  //     if (Clocktime) {
  //       setShow(type);
  //       var dt = new Date(Clocktime);
  //       dt.setSeconds(0);
  //       var dt1 = dt.toLocaleString();
  //       setSpecificDate(dt1);
  //     }
  //   } else  {
  //     return
  //   }
  // }

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Retopup History</h4>

                <div className="row">
                  <div className="col-lg-6 ">
                    <Form.Group>
                      <div
                        className="input-group"
                        // style={{ maxWidth: "350px" }}
                      >
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-gradient-primary"
                            type="button"
                            onClick={() => setSearch("")}
                          >
                            <i className="mdi mdi-close-circle menu-icon"></i>
                          </button>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="row">
                      <div className="col-sm-12">
                        <select
                          className="form-control"
                          onChange={(e) => setCateFilter(e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="1">1 day</option>
                          <option value="3">3 days</option>
                          {/* <option
                                  id="PickStart"
                                  onClick={() => {
                                    DateChange(
                                    
                                      "PickSpecificDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </option> */}
                          {/* <option value="Communityactive">
                            Community Only Active
                          </option>
                          <option value="Communityinactive">
                            Community Only Inactive
                          </option> */}
                        </select>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={datas}
                    noHeader
                    pagination={true}
                    paginationPerPage="10"
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Choose date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Datetime
            minDate={new Date().getTime()}
            input={false}
            value={Clocktime}
            onChange={(value) => handlesetClocktime(value)}
          />
          <button
            type="submit"
            className="btn btn-gradient-primary mr-2 mt-10"
            onClick={() => ModalAction("calendar_modal", false)}
            disabled={startTimeErr ? true : false}
          >
            Submit
          </button>
          {startTimeErr && <span className="error-msg">{startTimeErr}</span>}
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default DepositeHistory;
